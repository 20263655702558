var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "form-main" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Renda Não Comprovada / Informal")])
          ])
        ],
        1
      ),
      _c(
        "v-form",
        [
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("\n        Nome da Atividade\n      ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.rendaInformal.atividade,
                          expression: "rendaInformal.atividade"
                        }
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { id: "cmbAtividade", name: "cmbAtividade" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.rendaInformal,
                            "atividade",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    _vm._l(_vm.ocupacoesPorTipo.INFORMAL, function(item) {
                      return _c(
                        "option",
                        { key: item, domProps: { value: item } },
                        [_vm._v(_vm._s(item))]
                      )
                    }),
                    0
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("\n        Data de Início\n      ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: "##/##/####",
                        expression: "'##/##/####'"
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.rendaInformal.dataInicio,
                        expression: "rendaInformal.dataInicio"
                      }
                    ],
                    staticClass: "form-input-text",
                    staticStyle: { width: "40%" },
                    attrs: {
                      id: "dataInicio",
                      maxlength: "10",
                      name: "dataInicio",
                      size: "8",
                      type: "text"
                    },
                    domProps: { value: _vm.rendaInformal.dataInicio },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.rendaInformal,
                          "dataInicio",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("\n        Renda Líquida Mensal\n      ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.lazy",
                        value: _vm.rendaInformal.rendaLiquidaMensal,
                        expression: "rendaInformal.rendaLiquidaMensal",
                        modifiers: { lazy: true }
                      },
                      {
                        name: "currency",
                        rawName: "v-currency",
                        value: _vm.currencyOptions,
                        expression: "currencyOptions"
                      }
                    ],
                    attrs: {
                      id: "rendaLiquidaMensal",
                      maxlength: "14",
                      name: "rendaLiquidaMensal",
                      size: "23",
                      type: "text"
                    },
                    domProps: { value: _vm.rendaInformal.rendaLiquidaMensal },
                    on: {
                      change: function($event) {
                        return _vm.$set(
                          _vm.rendaInformal,
                          "rendaLiquidaMensal",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _vm._v("\n          Documentos de Comprovação\n          "),
                    _c("div", { staticClass: "d-flex flex-row align-center" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.rendaInformal.documentoComprovacao.value,
                              expression:
                                "rendaInformal.documentoComprovacao.value"
                            }
                          ],
                          attrs: {
                            id: "docCompAtivNComp",
                            name: "docCompAtivNComp"
                          },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.rendaInformal.documentoComprovacao,
                                "value",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.simNao, function(item) {
                          return _c(
                            "option",
                            { key: item, domProps: { value: item } },
                            [_vm._v(_vm._s(item))]
                          )
                        }),
                        0
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c("div", { staticClass: "d-flex flex-row align-center" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.rendaInformal.documentoComprovacao
                                .aguaEsgotoEnergiaEletricaTel.selected,
                            expression:
                              "rendaInformal.documentoComprovacao.aguaEsgotoEnergiaEletricaTel.selected"
                          }
                        ],
                        attrs: {
                          disabled: _vm.isDocComprovacaoNotSim,
                          id: "aguaEsgotoEnergiaEletricaTel",
                          name: "aguaEsgotoEnergiaEletricaTel",
                          type: "checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(
                            _vm.rendaInformal.documentoComprovacao
                              .aguaEsgotoEnergiaEletricaTel.selected
                          )
                            ? _vm._i(
                                _vm.rendaInformal.documentoComprovacao
                                  .aguaEsgotoEnergiaEletricaTel.selected,
                                null
                              ) > -1
                            : _vm.rendaInformal.documentoComprovacao
                                .aguaEsgotoEnergiaEletricaTel.selected
                        },
                        on: {
                          change: function($event) {
                            var $$a =
                                _vm.rendaInformal.documentoComprovacao
                                  .aguaEsgotoEnergiaEletricaTel.selected,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.rendaInformal.documentoComprovacao
                                      .aguaEsgotoEnergiaEletricaTel,
                                    "selected",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.rendaInformal.documentoComprovacao
                                      .aguaEsgotoEnergiaEletricaTel,
                                    "selected",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.rendaInformal.documentoComprovacao
                                  .aguaEsgotoEnergiaEletricaTel,
                                "selected",
                                $$c
                              )
                            }
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-checkbox-label",
                          attrs: { for: "aguaEsgotoEnergiaEletricaTel" }
                        },
                        [_vm._v("ÁGUA E ESGOTO, ENERGIA ELÉTRICA, TELEFONE")]
                      )
                    ]),
                    _c("div", { staticClass: "d-flex flex-row align-center" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.rendaInformal.documentoComprovacao
                                .faturaCartaoExtratoCC.selected,
                            expression:
                              "rendaInformal.documentoComprovacao.faturaCartaoExtratoCC.selected"
                          }
                        ],
                        attrs: {
                          disabled: _vm.isDocComprovacaoNotSim,
                          id: "faturaCartaoExtratoCC",
                          name: "faturaCartaoExtratoCC",
                          type: "checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(
                            _vm.rendaInformal.documentoComprovacao
                              .faturaCartaoExtratoCC.selected
                          )
                            ? _vm._i(
                                _vm.rendaInformal.documentoComprovacao
                                  .faturaCartaoExtratoCC.selected,
                                null
                              ) > -1
                            : _vm.rendaInformal.documentoComprovacao
                                .faturaCartaoExtratoCC.selected
                        },
                        on: {
                          change: function($event) {
                            var $$a =
                                _vm.rendaInformal.documentoComprovacao
                                  .faturaCartaoExtratoCC.selected,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.rendaInformal.documentoComprovacao
                                      .faturaCartaoExtratoCC,
                                    "selected",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.rendaInformal.documentoComprovacao
                                      .faturaCartaoExtratoCC,
                                    "selected",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.rendaInformal.documentoComprovacao
                                  .faturaCartaoExtratoCC,
                                "selected",
                                $$c
                              )
                            }
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-checkbox-label",
                          attrs: { for: "faturaCartaoExtratoCC" }
                        },
                        [_vm._v("FATURA CARTÃO, EXTRATO C/C")]
                      )
                    ]),
                    _c("div", { staticClass: "d-flex flex-row align-center" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.rendaInformal.documentoComprovacao
                                .previdenciaConsorcioSeguroPlanoSaude.selected,
                            expression:
                              "rendaInformal.documentoComprovacao.previdenciaConsorcioSeguroPlanoSaude.selected"
                          }
                        ],
                        attrs: {
                          disabled: _vm.isDocComprovacaoNotSim,
                          id: "previdenciaConsorcioSeguroPlanoSaude",
                          name: "previdenciaConsorcioSeguroPlanoSaude",
                          type: "checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(
                            _vm.rendaInformal.documentoComprovacao
                              .previdenciaConsorcioSeguroPlanoSaude.selected
                          )
                            ? _vm._i(
                                _vm.rendaInformal.documentoComprovacao
                                  .previdenciaConsorcioSeguroPlanoSaude
                                  .selected,
                                null
                              ) > -1
                            : _vm.rendaInformal.documentoComprovacao
                                .previdenciaConsorcioSeguroPlanoSaude.selected
                        },
                        on: {
                          change: function($event) {
                            var $$a =
                                _vm.rendaInformal.documentoComprovacao
                                  .previdenciaConsorcioSeguroPlanoSaude
                                  .selected,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.rendaInformal.documentoComprovacao
                                      .previdenciaConsorcioSeguroPlanoSaude,
                                    "selected",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.rendaInformal.documentoComprovacao
                                      .previdenciaConsorcioSeguroPlanoSaude,
                                    "selected",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.rendaInformal.documentoComprovacao
                                  .previdenciaConsorcioSeguroPlanoSaude,
                                "selected",
                                $$c
                              )
                            }
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-checkbox-label",
                          attrs: { for: "previdenciaConsorcioSeguroPlanoSaude" }
                        },
                        [
                          _vm._v(
                            "PREVIDÊNCIA, CONSÓRCIO, SEGURO, PLANO DE SAÚDE"
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "d-flex flex-row align-center" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.rendaInformal.documentoComprovacao
                                .tvInternetJornaisRevistas.selected,
                            expression:
                              "rendaInformal.documentoComprovacao.tvInternetJornaisRevistas.selected"
                          }
                        ],
                        attrs: {
                          disabled: _vm.isDocComprovacaoNotSim,
                          id: "tvInternetJornaisRevistas",
                          name: "tvInternetJornaisRevistas",
                          type: "checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(
                            _vm.rendaInformal.documentoComprovacao
                              .tvInternetJornaisRevistas.selected
                          )
                            ? _vm._i(
                                _vm.rendaInformal.documentoComprovacao
                                  .tvInternetJornaisRevistas.selected,
                                null
                              ) > -1
                            : _vm.rendaInformal.documentoComprovacao
                                .tvInternetJornaisRevistas.selected
                        },
                        on: {
                          change: function($event) {
                            var $$a =
                                _vm.rendaInformal.documentoComprovacao
                                  .tvInternetJornaisRevistas.selected,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.rendaInformal.documentoComprovacao
                                      .tvInternetJornaisRevistas,
                                    "selected",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.rendaInformal.documentoComprovacao
                                      .tvInternetJornaisRevistas,
                                    "selected",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.rendaInformal.documentoComprovacao
                                  .tvInternetJornaisRevistas,
                                "selected",
                                $$c
                              )
                            }
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-checkbox-label",
                          attrs: { for: "tvInternetJornaisRevistas" }
                        },
                        [
                          _vm._v(
                            "TV A CABO, INTERNET, ASSINATURA JORNAIS/REVISTAS"
                          )
                        ]
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "text-center my-3" }, [
            _c(
              "a",
              {
                staticClass: "btn-azul",
                attrs: { id: "btn-salvar" },
                on: { click: _vm.salvar }
              },
              [_vm._v("Salvar")]
            )
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Lista de Rendas Não Comprovadas / Informais")])
          ])
        ],
        1
      ),
      _vm._l(_vm.rendasInformais, function(renda, index) {
        return _c(
          "div",
          { key: index },
          [
            _c(
              "v-row",
              {
                staticClass: "form-row-result form-row-result-first",
                attrs: { "no-gutters": "" }
              },
              [
                _c(
                  "v-col",
                  {
                    class: [..._vm.style.colLeftClasses, "form-result-label"],
                    attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                  },
                  [_vm._v("\n        Atividade\n      ")]
                ),
                _c(
                  "v-col",
                  {
                    class: _vm.style.colRightClasses,
                    attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                  },
                  [_vm._v("\n        " + _vm._s(renda.atividade) + "\n      ")]
                )
              ],
              1
            ),
            _c(
              "v-row",
              { staticClass: "form-row-result", attrs: { "no-gutters": "" } },
              [
                _c(
                  "v-col",
                  {
                    class: [..._vm.style.colLeftClasses, "form-result-label"],
                    attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                  },
                  [_vm._v("\n        Data Início\n      ")]
                ),
                _c(
                  "v-col",
                  {
                    class: _vm.style.colRightClasses,
                    attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                  },
                  [_vm._v("\n        " + _vm._s(renda.dataInicio) + "\n      ")]
                )
              ],
              1
            ),
            _c(
              "v-row",
              { staticClass: "form-row-result", attrs: { "no-gutters": "" } },
              [
                _c(
                  "v-col",
                  {
                    class: [..._vm.style.colLeftClasses, "form-result-label"],
                    attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                  },
                  [_vm._v("\n        Renda Liquida\n      ")]
                ),
                _c(
                  "v-col",
                  {
                    class: _vm.style.colRightClasses,
                    attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(renda.rendaLiquidaMensal) +
                        "\n      "
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "v-row",
              { staticClass: "form-row-result", attrs: { "no-gutters": "" } },
              [
                _c(
                  "v-col",
                  {
                    class: [..._vm.style.colLeftClasses, "form-result-label"],
                    attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                  },
                  [_vm._v("\n        Comprovante de Renda Informal\n      ")]
                ),
                _c(
                  "v-col",
                  {
                    class: _vm.style.colRightClasses,
                    attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column" },
                      _vm._l(
                        _vm.comprovanteDeRendaInformal(
                          renda.documentoComprovacao
                        ),
                        function(comp, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "d-flex flex-row align-center"
                            },
                            [
                              _vm._v(
                                "\n            " + _vm._s(comp) + "\n          "
                              )
                            ]
                          )
                        }
                      ),
                      0
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "v-row",
              { staticClass: "ma-1", attrs: { "no-gutters": "" } },
              [
                _c("v-col", { staticClass: "text-right" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-azul",
                      on: {
                        click: function($event) {
                          return _vm.excluir(renda)
                        }
                      }
                    },
                    [_vm._v("Excluir")]
                  )
                ])
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }