<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <v-container class="form-main">
    <v-row no-gutters>
      <v-col class="form-header text-center">
        <div>Renda Não Comprovada / Informal</div>
      </v-col>
    </v-row>
    <v-form>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          Nome da Atividade
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <select id="cmbAtividade" name="cmbAtividade" style="width:100%" v-model="rendaInformal.atividade">
            <option :key="item" :value="item" v-for="item in ocupacoesPorTipo.INFORMAL">{{ item }}</option>
          </select>
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          Data de Início
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <input
            class="form-input-text"
            id="dataInicio"
            maxlength="10"
            name="dataInicio"
            size="8"
            style="width:40%"
            type="text"
            v-mask="'##/##/####'"
            v-model="rendaInformal.dataInicio"
          />
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          Renda Líquida Mensal
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <input
            id="rendaLiquidaMensal"
            maxlength="14"
            name="rendaLiquidaMensal"
            size="23"
            type="text"
            v-model.lazy="rendaInformal.rendaLiquidaMensal"
            v-currency="currencyOptions"
          />
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          <div class="d-flex flex-column">
            Documentos de Comprovação
            <div class="d-flex flex-row align-center">
              <select id="docCompAtivNComp" name="docCompAtivNComp" v-model="rendaInformal.documentoComprovacao.value">
                <option :key="item" :value="item" v-for="item in simNao">{{ item }}</option>
              </select>
            </div>
          </div>
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <div class="d-flex flex-column">
            <div class="d-flex flex-row align-center">
              <input
                :disabled="isDocComprovacaoNotSim"
                id="aguaEsgotoEnergiaEletricaTel"
                name="aguaEsgotoEnergiaEletricaTel"
                type="checkbox"
                v-model="rendaInformal.documentoComprovacao.aguaEsgotoEnergiaEletricaTel.selected"
              />
              <label class="form-checkbox-label" for="aguaEsgotoEnergiaEletricaTel"
                >ÁGUA E ESGOTO, ENERGIA ELÉTRICA, TELEFONE</label
              >
            </div>
            <div class="d-flex flex-row align-center">
              <input
                :disabled="isDocComprovacaoNotSim"
                id="faturaCartaoExtratoCC"
                name="faturaCartaoExtratoCC"
                type="checkbox"
                v-model="rendaInformal.documentoComprovacao.faturaCartaoExtratoCC.selected"
              />
              <label class="form-checkbox-label" for="faturaCartaoExtratoCC">FATURA CARTÃO, EXTRATO C/C</label>
            </div>
            <div class="d-flex flex-row align-center">
              <input
                :disabled="isDocComprovacaoNotSim"
                id="previdenciaConsorcioSeguroPlanoSaude"
                name="previdenciaConsorcioSeguroPlanoSaude"
                type="checkbox"
                v-model="rendaInformal.documentoComprovacao.previdenciaConsorcioSeguroPlanoSaude.selected"
              />
              <label class="form-checkbox-label" for="previdenciaConsorcioSeguroPlanoSaude"
                >PREVIDÊNCIA, CONSÓRCIO, SEGURO, PLANO DE SAÚDE</label
              >
            </div>
            <div class="d-flex flex-row align-center">
              <input
                :disabled="isDocComprovacaoNotSim"
                id="tvInternetJornaisRevistas"
                name="tvInternetJornaisRevistas"
                type="checkbox"
                v-model="rendaInformal.documentoComprovacao.tvInternetJornaisRevistas.selected"
              />
              <label class="form-checkbox-label" for="tvInternetJornaisRevistas"
                >TV A CABO, INTERNET, ASSINATURA JORNAIS/REVISTAS</label
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </v-form>

    <v-row no-gutters>
      <v-col class="text-center my-3">
        <a @click="salvar" class="btn-azul" id="btn-salvar">Salvar</a>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="form-header text-center">
        <div>Lista de Rendas Não Comprovadas / Informais</div>
      </v-col>
    </v-row>

    <div :key="index" v-for="(renda, index) in rendasInformais">
      <v-row class="form-row-result form-row-result-first" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Atividade
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ renda.atividade }}
        </v-col>
      </v-row>
      <v-row class="form-row-result" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Data Início
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ renda.dataInicio }}
        </v-col>
      </v-row>
      <v-row class="form-row-result" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Renda Liquida
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ renda.rendaLiquidaMensal }}
        </v-col>
      </v-row>
      <v-row class="form-row-result" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Comprovante de Renda Informal
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          <div class="d-flex flex-column">
            <div
              :key="index"
              class="d-flex flex-row align-center"
              v-for="(comp, index) in comprovanteDeRendaInformal(renda.documentoComprovacao)"
            >
              {{ comp }}
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="ma-1" no-gutters>
        <v-col class="text-right">
          <a @click="excluir(renda)" class="btn btn-azul">Excluir</a>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
// noinspection ES6CheckImport
import _ from "lodash";
import { isNotAfterToday, isValid, isValidDate, requiredCheckIf, requiredMoney } from "../../../validators";
import { mapDadosGeraisFields } from "../../../store/modules/dados-gerais";
import { createNamespacedHelpers } from "vuex";
import { mapClienteFields } from "../../../store/modules/caixaqui-clientes";
import { OPTIONS } from "../../../config";
import { required } from "vuelidate/lib/validators";

const { mapMutations: mapClientesMutations } = createNamespacedHelpers("caixaquiClientes");

export default {
  name: "CaixaquiCadastroClientesRendaInformal",

  data: () => ({
    style: {
      colLeft: 4,
      colRight: 8,
      alignSelf: "stretch",
      colLeftClasses: ["form-col-left", "d-flex", "flex-row", "align-center"],
      colRightClasses: ["form-col-right", "d-flex", "flex-row", "align-center"]
    },
    currencyOptions: OPTIONS.currency,
    rendaInformal: {},
    rendaInformalNew: {
      atividade: null,
      dataInicio: null,
      rendaLiquidaMensal: null,
      documentoComprovacao: {
        value: null,
        aguaEsgotoEnergiaEletricaTel: { selected: false, text: "ÁGUA E ESGOTO, ENERGIA ELÉTRICA, TELEFONE" },
        faturaCartaoExtratoCC: { selected: false, text: "FATURA CARTÃO, EXTRATO C/C" },
        previdenciaConsorcioSeguroPlanoSaude: {
          selected: false,
          text: "PREVIDÊNCIA, CONSÓRCIO, SEGURO, PLANO DE SAÚDE"
        },
        tvInternetJornaisRevistas: { selected: false, text: "TV A CABO, INTERNET, ASSINATURA JORNAIS/REVISTAS" }
      }
    },
    validationMessages: {
      "rendaInformal.atividade": { required: "Selecione um nome de atividade." },
      "rendaInformal.dataInicio": {
        isValidDate: "Data inicial inválida.",
        isNotAfterToday: "Data de início não pode ser maior que data atual"
      },
      "rendaInformal.rendaLiquidaMensal": { requiredMoney: "Preencha a renda líquida mensal." },
      "rendaInformal.documentoComprovacao.value": { required: "Informe a existência de um Documento de Comprovação" },
      "rendaInformal.documentoComprovacao.aguaEsgotoEnergiaEletricaTel.selected": {
        required: "Selecione ao menos um Documento de Comprovação"
      },
      "rendaInformal.documentoComprovacao.faturaCartaoExtratoCC.selected": {
        required: "Selecione ao menos um Documento de Comprovação"
      },
      "rendaInformal.documentoComprovacao.previdenciaConsorcioSeguroPlanoSaude.selected": {
        required: "Selecione ao menos um Documento de Comprovação"
      },
      "rendaInformal.documentoComprovacao.tvInternetJornaisRevistas.selected": {
        required: "Selecione ao menos um Documento de Comprovação"
      }
    }
  }),
  validations() {
    return {
      rendaInformal: {
        atividade: { required },
        dataInicio: { isValidDate, isNotAfterToday },
        rendaLiquidaMensal: { requiredMoney },
        documentoComprovacao: {
          value: { required },
          aguaEsgotoEnergiaEletricaTel: {
            selected: { required: requiredCheckIf(this.anyDocComprovacaoNotSelectedIfSim) }
          },
          faturaCartaoExtratoCC: { selected: { required: requiredCheckIf(this.anyDocComprovacaoNotSelectedIfSim) } },
          previdenciaConsorcioSeguroPlanoSaude: {
            selected: { required: requiredCheckIf(this.anyDocComprovacaoNotSelectedIfSim) }
          },
          tvInternetJornaisRevistas: { selected: { required: requiredCheckIf(this.anyDocComprovacaoNotSelectedIfSim) } }
        }
      }
    };
  },
  computed: {
    ...mapDadosGeraisFields(["ocupacoesPorTipo", "simNao"]),
    ...mapClienteFields(["rendasInformais"]),
    isDocComprovacaoNotSim() {
      return this.rendaInformal.documentoComprovacao.value !== "SIM";
    },
    comprovanteDeRendaInformal() {
      return documentoComprovacao => {
        const result = [];
        if (documentoComprovacao && documentoComprovacao.value === "SIM") {
          Object.keys(documentoComprovacao)
            .filter(key => key !== "value")
            .forEach(key => {
              if (documentoComprovacao[key].selected) {
                result.push(documentoComprovacao[key].text);
              }
            });
        } else {
          result.push("NÃO");
        }
        return result;
      };
    },
    anyDocComprovacaoNotSelectedIfSim() {
      return (
        this.rendaInformal.documentoComprovacao.value === "SIM" &&
        !(
          this.rendaInformal.documentoComprovacao.aguaEsgotoEnergiaEletricaTel.selected ||
          this.rendaInformal.documentoComprovacao.faturaCartaoExtratoCC.selected ||
          this.rendaInformal.documentoComprovacao.previdenciaConsorcioSeguroPlanoSaude.selected ||
          this.rendaInformal.documentoComprovacao.tvInternetJornaisRevistas.selected
        )
      );
    }
  },
  watch: {
    "rendaInformal.documentoComprovacao.value": function(val) {
      if (val !== "SIM") {
        this.rendaInformal.documentoComprovacao.aguaEsgotoEnergiaEletricaTel.selected = false;
        this.rendaInformal.documentoComprovacao.faturaCartaoExtratoCC.selected = false;
        this.rendaInformal.documentoComprovacao.previdenciaConsorcioSeguroPlanoSaude.selected = false;
        this.rendaInformal.documentoComprovacao.tvInternetJornaisRevistas.selected = false;
      }
    }
  },
  created() {
    this.rendaInformal = _.cloneDeep(this.rendaInformalNew);
  },
  methods: {
    handleForwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE AVANCAR PARA A PROXIMA PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    handleBackwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE VOLTAR PARA A PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    salvar() {
      if (isValid(this.$v, this.validationMessages)) {
        this.addRendaInformal(this.rendaInformal);
        this.rendaInformal = _.cloneDeep(this.rendaInformalNew);
      }
    },
    excluir(renda) {
      this.removeRendaInformal(renda);
    },
    ...mapClientesMutations(["addRendaInformal", "removeRendaInformal"])
  }
};
</script>

<style scoped src="../../../components/caixaqui/caixaqui-style.css"></style>
<style scoped>
#rendaLiquidaMensal {
  width: 175px;
  margin-right: 4px;
}

#docCompAtivNComp {
  width: 175px;
  margin-right: 4px;
}

.btn,
#btn-salvar {
  padding: 3px 12px 3px 12px;
}

.form-checkbox-label {
  font-weight: 100;
}
</style>
